export const RightClick = {
  sensitivity: 350,
  count: 0,

  allowedRoutes: ['/desktop', '/otherRoute1', '/otherRoute2'],  // Agrega aquí las rutas permitidas

  active(currentRoute) {
    if (this.allowedRoutes.includes(currentRoute)) {
      this.count++;
      setTimeout(() => { this.count = 0; }, this.sensitivity);
      window.addEventListener("click", this.closeContextMenu);
    }
    // else if (currentRoute === '/') {
    //   this.count++;
    //   setTimeout(() => { this.count = 0; }, this.sensitivity);
    // }
    
  },
  displayContextMenu(e, currentRoute) {
    if (!this.allowedRoutes.includes(currentRoute)) {
      return;
    }
    const halfBodyWidth = document.body.clientWidth /2;
    const halfBodyHeight = document.body.clientHeight /2;
    this.contextMenu = document.getElementById("context-menu") || this.contextMenu;
    this.contextMenu?.classList.add("active");
    const yPos = Math.min(e.clientY, window.innerHeight - this.contextMenu?.offsetHeight);
    const xPos = Math.min(e.clientX, window.innerWidth - this.contextMenu?.offsetWidth);
    this.contextMenu?.style.setProperty('left', `${xPos}px`);
    this.contextMenu?.style.setProperty('top', `${yPos}px`);
    const origin = e.clientX > halfBodyWidth ? (e.clientY < halfBodyHeight ? 'top right' : 'bottom right') : (e.clientY < halfBodyHeight ? 'top left' : 'bottom left');
    this.contextMenu?.style.setProperty('transform-origin', e.clientY + this.contextMenu?.offsetHeight > window.innerHeight && e.clientX + this.contextMenu?.offsetWidth <= window.innerWidth ? 'bottom left' : origin);
  },
};

// Removemos los listeners del evento global para evitar interferencias


window.addEventListener("click", () => {
  document.getElementById("context-menu")?.classList.remove("active");
});
