<template>
    <v-row class="ma-0 d-flex justify-center align-center" :class="dark_mode ? 'nav_bar_dark' : 'nav_bar_light'" dense>
        <div class="px-10 d-flex justify-space-between" style="width: 100%">
            <div>
                <router-link to="/"
                    :style="dark_mode ? { 'text-decoration': 'none !important', 'color': '#fff' } : { 'text-decoration': 'none !important', 'color': '#032030' }"
                    style=" max-width: 275px;"
                    class="d-flex align-center">
                    <v-img src="../../assets/images/Logo.svg" style="max-width:45px" class="mr-4" />
                    <h2 class="montserrat-semibold mr-1">Jonathan</h2>
                    <h2 class="montserrat-extralight">Trujillo</h2>
                    <h1 style="color:#00ff00">.</h1>
                </router-link>
            </div>
            <v-col class="pa-0 d-flex justify-center align-center" style="gap:20px">
                <nav v-for="item in idioma === 'MX' ? items_es : items_en" :key="item.id">
                    <router-link :class="dark_mode ? 'btn-nav-dark' : 'btn-nav-light'" :to="item.path">{{ item.name
                    }}</router-link>
                </nav>
            </v-col>
            <div class="d-flex justify-end align-center">
                <v-menu rounded transition="slide-y-transition">
                    <template v-slot:activator="{ props }">
                        <v-btn elevation="0" rounded variant="text" v-bind="props" class="ml-8" style="background-color:#00bd7e20 !important;width:35px !important; height: 30px !important;">
                            <span :style="green_primary">#</span>{{ idioma }}
                            <!-- <v-icon>mdi-chevron-down</v-icon> -->
                        </v-btn>
                    </template>
                    <v-card class="mt-4 ml-n7" elevation="0"
                        style="background:#002533c6;color:#fff;backdrop-filter: blur(3px);z-index: 5;">
                        <v-card-text>
                            <div class="mx-auto text-center">
                                <v-btn rounded variant="text" @click="idioma = 'MX'">
                                    Español
                                </v-btn>
                                <v-divider class="my-3"></v-divider>
                                <v-btn rounded variant="text" @click="idioma = 'US'">
                                    Inglés
                                </v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-menu>

                <!-- <v-switch v-model="idioma" class="switch-idiomas" :class="!idioma ? 'mx_idioma' : 'en_idioma'"
                    style="max-width:45px;padding:0;margin:0" :hint="false" dense /> -->

                <v-btn class="ml-3 d-flex align-center btn-mode-dark-light"
                    :class="dark_mode ? 'btn-mode-dark' : 'btn-mode-light'" variant="text" @click="btn_mode()" style="background-color:#00bd7e20 !important;width:15px !important; height: 15px !important;">
                    <svg v-if="!dark_mode" height="25" width="25px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <title />
                        <circle cx="12" cy="12" r="5" />
                        <path d="M21,13H20a1,1,0,0,1,0-2h1a1,1,0,0,1,0,2Z" />
                        <path d="M4,13H3a1,1,0,0,1,0-2H4a1,1,0,0,1,0,2Z" />
                        <path
                            d="M17.66,7.34A1,1,0,0,1,17,7.05a1,1,0,0,1,0-1.41l.71-.71a1,1,0,1,1,1.41,1.41l-.71.71A1,1,0,0,1,17.66,7.34Z" />
                        <path
                            d="M5.64,19.36a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.41L5.64,17a1,1,0,0,1,1.41,1.41l-.71.71A1,1,0,0,1,5.64,19.36Z" />
                        <path d="M12,5a1,1,0,0,1-1-1V3a1,1,0,0,1,2,0V4A1,1,0,0,1,12,5Z" />
                        <path d="M12,22a1,1,0,0,1-1-1V20a1,1,0,0,1,2,0v1A1,1,0,0,1,12,22Z" />
                        <path
                            d="M6.34,7.34a1,1,0,0,1-.7-.29l-.71-.71A1,1,0,0,1,6.34,4.93l.71.71a1,1,0,0,1,0,1.41A1,1,0,0,1,6.34,7.34Z" />
                        <path
                            d="M18.36,19.36a1,1,0,0,1-.7-.29L17,18.36A1,1,0,0,1,18.36,17l.71.71a1,1,0,0,1,0,1.41A1,1,0,0,1,18.36,19.36Z" />
                    </svg>

                    <svg v-if="dark_mode" width="20" viewBox="0 0 512 512" enable-background="new 0 0 512 512"
                        xml:space="preserve">

                        <path opacity="1.000000" stroke="none" d="M361.129211,482.160767 
                            C339.001068,490.982910 316.504425,496.567993 293.169159,499.140259 
                            C260.308929,502.762451 228.099930,499.846741 196.358307,490.869843 
                            C173.295471,484.347473 151.670929,474.658447 131.354965,461.924927 
                            C115.912483,452.246002 101.794502,440.932007 88.781143,428.239838 
                            C62.771500,402.872070 43.428349,373.066132 30.274080,339.242371 
                            C20.417707,313.898529 15.335516,287.621490 13.787812,260.435760 
                            C11.933737,227.868561 16.615078,196.341431 27.061016,165.671066 
                            C37.749577,134.288330 54.285587,106.196709 76.167023,81.165329 
                            C101.846741,51.788879 132.908554,30.095016 168.580032,14.721066 
                            C169.448883,14.346601 170.452087,14.283878 171.611328,14.027367 
                            C119.978333,119.328377 129.455276,216.326263 209.538818,304.040741 
                            C297.217926,384.462097 394.215424,393.948090 500.409698,342.205627 
                            C487.672150,374.235352 469.169250,401.983490 445.262909,426.160034 
                            C421.197876,450.497040 393.120850,468.965485 361.129211,482.160767 z" />
                        <path opacity="1.000000" stroke="none" d="M410.906403,162.027023 
                            C400.646484,157.089111 390.708862,152.320251 380.771240,147.551392 
                            C380.773987,147.036041 380.776733,146.520706 380.779480,146.005371 
                            C386.645325,143.125259 392.316864,139.718964 398.414703,137.466888 
                            C414.602448,131.488388 425.595459,120.841843 431.128693,104.325912 
                            C433.103180,98.432320 436.402679,92.982651 439.765900,85.928017 
                            C442.047577,90.330368 443.751373,93.456932 445.306000,96.655975 
                            C447.487762,101.145515 449.965149,105.547020 451.601929,110.235497 
                            C456.827728,125.204597 468.614990,132.570084 482.407196,137.987244 
                            C488.330750,140.313828 493.994873,143.300873 499.776886,145.987839 
                            C499.760162,146.485641 499.743439,146.983459 499.726715,147.481262 
                            C494.978882,149.679123 490.167236,151.750351 485.498138,154.104324 
                            C478.382629,157.691666 470.960938,160.876984 464.404236,165.318115 
                            C455.140320,171.592957 451.926880,182.291565 447.346619,191.858582 
                            C445.006165,196.747116 442.900726,201.748184 440.250946,207.678940 
                            C438.978027,205.766815 438.287323,204.951920 437.838287,204.020538 
                            C433.068542,194.126999 428.888397,183.892807 423.365356,174.442322 
                            C420.557098,169.637146 415.350891,166.233368 410.906403,162.027023 z" />
                        <path opacity="1.000000" stroke="none" d="M259.299683,157.734650 
                            C262.732697,170.650604 271.262787,178.107544 282.996918,182.614746 
                            C288.492981,184.725815 293.833588,187.241623 299.244629,189.573990 
                            C299.227692,190.099792 299.210754,190.625610 299.193848,191.151428 
                            C297.606598,192.105957 296.070984,193.162399 294.423157,193.997375 
                            C287.297211,197.608215 280.094360,201.069305 273.004730,204.749146 
                            C266.657654,208.043518 263.919678,214.200394 261.130096,220.291504 
                            C258.499298,226.035950 255.777267,231.738953 253.043182,237.435150 
                            C252.788559,237.965652 252.158051,238.315720 251.009537,239.408478 
                            C249.544754,236.574356 248.209763,234.196045 247.062302,231.730438 
                            C244.324722,225.848022 241.856995,219.832916 238.936737,214.044739 
                            C236.330276,208.878555 232.375793,205.106705 226.829865,202.759430 
                            C218.902817,199.404373 211.224594,195.461365 202.165817,191.165100 
                            C207.530014,188.350418 211.477295,185.257233 215.923706,184.126144 
                            C233.524658,179.648819 240.751129,165.940979 246.837021,150.947205 
                            C247.929657,148.255234 249.368042,145.703568 250.646790,143.087128 
                            C251.228622,143.014862 251.810455,142.942596 252.392288,142.870331 
                            C254.643082,147.707520 256.893890,152.544708 259.299683,157.734650 z" />
                        <path opacity="1.000000" stroke="none" d="M334.071259,74.894730 
                            C333.239349,76.419266 332.548676,77.625145 331.574066,79.326759 
                            C327.296478,71.787033 324.564240,63.798843 319.206879,58.430294 
                            C313.966675,53.179146 306.049988,50.598892 298.819153,46.574078 
                            C306.084167,42.447983 314.126617,39.761356 319.386993,34.363483 
                            C324.642548,28.970545 327.113739,20.864170 331.746979,13.415401 
                            C336.942352,29.963181 347.237396,41.189579 364.418060,46.332851 
                            C350.081665,52.046658 338.675262,59.695652 334.071259,74.894730 z" />
                        <path opacity="1.000000" stroke="none" d="M365.002502,262.010925 
                            C367.807587,270.310425 375.098694,272.526459 381.647797,275.652222 
                            C384.295959,276.916138 386.969238,278.127563 390.490021,279.761505 
                            C382.905426,284.235596 375.130371,287.118195 369.796356,292.445099 
                            C364.402069,297.832245 361.424988,305.639801 357.164825,312.761871 
                            C353.201660,305.764038 350.475159,297.820404 345.154510,292.444153 
                            C339.941193,287.176422 332.086914,284.522400 324.308868,280.106384 
                            C327.768372,278.284607 330.440430,276.260864 333.444885,275.408203 
                            C343.402740,272.582092 348.902222,265.735657 352.066223,256.356842 
                            C353.045776,253.453217 354.552704,250.727539 355.818207,247.920395 
                            C356.412994,247.774216 357.007812,247.628052 357.602631,247.481873 
                            C360.018524,252.211777 362.434418,256.941681 365.002502,262.010925 z" />
                    </svg>
                </v-btn>
                <notifications_section />

                <!-- <button @click="requestFullscreen">Haz click para entrar en modo de pantalla completa</button> -->
                <v-menu rounded transition="slide-y-transition">
                    <template v-slot:activator="{ props }">
                        <v-btn elevation="0" icon v-bind="props" variant="tonal" class="ml-6"  style="width:45px !important; height: 45px !important;">
                            <v-avatar :color="dark_mode ? '#ffffff00' : '#242526'" size="large"  style="width:45px !important; height: 45px !important;">
                                <strong
                                    :style="!dark_mode ? { 'color': '#fff' } : { 'color': '#fff' }">{{
                                        user.initials }}</strong>
                            </v-avatar>
                        </v-btn>
                    </template>
                    <v-card class="mt-2 ml-n7"
                        style="background: #002533c6;color:#fff;backdrop-filter: blur(3px);z-index: 5;">
                        <v-card-text>
                            <v-col class="pa-0">
                                <v-col cols="12" class="d-flex align-center">
                                    <v-avatar :color="dark_mode ? '#c9ccd1' : '#032030'">
                                    <span class="text-h5"
                                        :style="!dark_mode ? { 'color': '#c9ccd1' } : { 'color': '#032030' }">{{
                                            user.initials }}</span>
                                    </v-avatar>
                                    <v-col class="text-left">
                                        <h3>{{ user.fullName }}</h3>
                                        <p class="text-caption mt-1">
                                            {{ user.email }}
                                        </p>
                                    </v-col>
                                    
                                </v-col>
                                <v-divider class="my-3"></v-divider>
                                <v-btn rounded variant="text" class="d-flex justify-start align-center" style="width: 100%;">
                                    Edit Account
                                </v-btn>
                                <v-divider class="my-3"></v-divider>
                                <v-btn rounded variant="text" class="d-flex justify-start align-center" style="width: 100%;">
                                    Disconnect
                                </v-btn>
                            </v-col>
                        </v-card-text>
                    </v-card>
                </v-menu>
            </div>
        </div>
    </v-row>
    <redes_sociales/>
</template>


<script>
import notifications_section from '../NotificationsSection.vue'
import redes_sociales from './modulos/RedesSociales.vue'
import { btn_mode_dark_light, state } from '../../funciones-globales'

export default {
    components: {
        notifications_section,
        redes_sociales
    },
    data: () => ({
        idioma: 'MX',
        items_es: [
            {
                id: 1,
                path: '/',
                name: 'Inicio'
            },
            {
                id: 2,
                path: 'desktop',
                name: 'Escritorio'
            },
            {
                id: 3,
                path: 'dashboard',
                name: 'Panel de Control'
            },
            {
                id: 4,
                path: 'propinas',
                name: 'Propinas'
            },
            {
                id: 5,
                path: 'orbital',
                name: 'Orbital'
            },
        ],
        items_en: [
            {
                id: 1,
                path: '/',
                name: 'Home'
            },
            {
                id: 2,
                path: 'desktop',
                name: 'Desktop'
            },
            {
                id: 3,
                path: 'dashboard',
                name: 'Dashboard'
            },
            {
                id: 4,
                path: 'propinas',
                name: 'Propinas'
            },
            {
                id: 5,
                path: 'orbital',
                name: 'Orbital'
            },
        ],
        user: {
            initials: 'JT',
            fullName: 'Jonathan Trujillo',
            email: 'jonathan.trujillo.dg@gmail.com',
        },
        colors: ['blue', 'green', 'orange']
        // isDarkMode: false
    }),
    computed: {
        dark_mode() {
            return state.dark_mode_value
        },
        green_primary() {
            return this.dark_mode ? 'color:#00ff00' : 'color:#009000; font-family:Montserrat-Medium'
        }
    },

    mounted() {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            btn_mode_dark_light()
        }
    },
    methods: {
        btn_mode() {
            btn_mode_dark_light()
        },
        notifications() {
            alert('Notificaciones')
        },
        ir_dashboard() {
            alert('Ir a Dashboard')
        }
    }
};
</script>

<style>
.switch-idiomas .v-input__details {
    display: none;
}

.switch-idiomas .v-switch__thumb {
    border-radius: 0;
    background-size: contain;
    height: 20px;
    width: 30px;
    background-color: transparent;
    box-shadow: none;
}

.switch-idiomas .v-switch__track {
    opacity: 0.2;
}

.mx_idioma .v-switch__thumb {
    background-image: url('../../assets/images/mx_idioma.webp');
}

.en_idioma .v-switch__thumb {
    background-image: url('../../assets/images/en_idioma.webp');
}</style>
