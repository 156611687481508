import { reactive, createApp } from 'vue';
import axios from 'axios';  // Importamos axios para usarlo en nuestras funciones

const app = createApp({});



export const state = reactive({

    // AQUI DECLARA LAS VARIABLES
    editingButtonId: null,
    dark_mode_value: false,
    buttonStyles: {},
    filas: [{ id: 0, datos: '', elementos: [] }],
    maximo_filas: 10,
    componentIdCounter: 0,  // contador para asignar un ID único a cada componente
    
    selectedSectionKey: 0,
    selectedElementId: 0

});


// AQUI EXPORTA LAS FUNCIONES
export const btn_mode_dark_light = () => {
    // alert('Hola Mundo')
    state.dark_mode_value = !state.dark_mode_value;
};

export const setEditingButton = (buttonId) => {
    state.editingButtonId = buttonId;
};



// export const setButtonStyles = (buttonStyle) => {
//     state.buttonStyle = buttonStyle;
// };

export const setButtonStyles = (id, styles) => {
    state.buttonStyles[id] = styles;
};

export const getButtonStyles = (id) => {
    return state.buttonStyles.id === id ? state.buttonStyles[id] : {}
};




// Nuestras funciones globales
export const agregar_seccion = () => {
    if (state.filas.length < state.maximo_filas) {
        state.componentIdCounter = state.componentIdCounter+1;  // Aumentamos el contador
        state.filas.push({ id: state.componentIdCounter, datos: '', elementos: [] });
        // Aquí puedes necesitar hacer más cosas dependiendo de cómo manejas los estilos y demás...
    }
};
// export const agregar_widget = (sectionKey, elementId) => {
//     state.selectedSectionKey = sectionKey;
//     state.selectedElementId = elementId;
// };

export const guardar_datos = () => {
    let baseComponentsData = state.filas;
    let payload = {
        baseComponents: baseComponentsData
    };
    let jsonData = JSON.stringify(payload);
    axios.post(`http://localhost:8085/api/sitio/guardarDatosSitio`, jsonData, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(response => {
        console.log('Datos enviados exitosamente:', response.data);
    })
    .catch(error => {
        console.error('Error enviando los datos:', error);
    });
};

export const mostrar_datos = async () => {
    try {
        const response = await axios.get('http://localhost:8085/api/sitio/mostrar_datos');
        if (response.data && response.data[0]) {
            const responseData = JSON.parse(response.data[0].datos);
            if (responseData.baseComponents) {
                state.filas = responseData.baseComponents;
            } else {
                console.warn("La respuesta no contiene los componentes base esperados.");
            }
        } else {
            console.warn("La respuesta no contiene los datos esperados.");
        }
    } catch (error) {
        console.error("Error al obtener los datos:", error);
    }
};

// AQUÍ DECLARA LAS FUNCIONES
app.config.globalProperties.btn_mode_dark_light = btn_mode_dark_light;
app.config.globalProperties.agregar_seccion = agregar_seccion;
// app.config.globalProperties.agregar_widget = agregar_widget; 
app.config.globalProperties.guardar_datos = guardar_datos;
app.config.globalProperties.mostrar_datos = mostrar_datos;

app.mount('#app');