<template>
  <v-row class="ma-0 background-image">
    <v-col cols="12" class="d-flex align-center justify-center">
      <v-row>
        <v-col class="d-flex align-center justify-center">
          <v-row style="max-width:65%">
            <v-col cols="12" style="position: relative;" class="d-flex align-end flex-column">

                <v-col cols="6" :style="option_01 === true ? {opacity:0.2}:{}" style="position: relative;" class="mr-16 d-flex justify-center flex-column">
                  <v-card class="pa-4"
                    style="color:#fff;border:1px solid #00eeff56;background:#05557423;border-radius: 10px;box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.2);">
                    <strong class="fs-24">Desarrollo Web</strong>
                    <v-col class="pa-0 d-flex align-center">
                      <div class="mr-2" style="width:5px;height: 5px;background:#00ff00;border-radius: 50px;"></div>
                      <!-- <div class="mr-2" style="width:5px;height: 5px;background:#ff0064;border-radius: 50px;"></div> -->
                      <h class="mr-2 fs-20">Full Stack</h>
                      <div class="mr-2" style="width:5px;height: 5px;background:#00a2ff;border-radius: 50px;"></div>
                    </v-col>
                    <p class="fs-16" style="color:#98ffc6">Domino tanto el front-end como el back-end, desde la creación de
                      interfaces de usuario
                      intuitivas hasta la implementación de soluciones back-end robustas.</p>
                  </v-card>
                </v-col>

                <v-col cols="6" :style="option_01 === true ? {'opacity':'0.2'}:{}" style="color:#fff;position: relative;"
                  class="d-flex justify-center flex-column">
                  <v-card class="pa-4"
                    style="color:#fff;border:1px solid #00ff8856;background:#05557423;border-radius: 10px;box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.2);">
                    <strong class="fs-24">Diseño Digital</strong>
                    <v-col class="pa-0 d-flex align-center">
                      <div class="mr-2" style="width:5px;height: 5px;background:#00a2ff;border-radius: 50px;"></div>
                      <h class="mr-2 fs-20">Ux <span style="color:#00ff00">/</span> Ui</h>
                      <div class="mr-2" style="width:5px;height: 5px;background:#ff0064;border-radius: 50px;"></div>
                    </v-col>

                    <p class="fs-16" style="color:#98d9ff;">He generado experiencias intuitivas y atractivas para
                      aplicaciones y videojuegos. Mezclando
                      estética y funcionalidad para mejorar la experiencia de usuario en plataformas digitales.</p>
                  </v-card>
                </v-col>

              <!-- <v-col cols="8" style="position: absolute;" v-if="option_01">
                <v-card class="pa-4"
                  style="color:#fff;border:1px solid #00fffb26;background:#001f2e82;border-radius: 10px;box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.2);backdrop-filter: blur(2.5px);height: 400px;box-shadow: 0px 0px 40px #00ff8863, 0px 5px 80px #00d9ff63;">
                  <calculadora v-if="currentApplication === 'Calculadora'"/>
                </v-card>
              </v-col> -->



            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" style="position:absolute;bottom:20px;color:#fff;gap:20px" class="d-flex justify-center">
    <v-btn variant="icon" class="icons-principal"
      v-for="(aplicacion, index) in aplicaciones" 
      :key="index" 
      @click="setApplication(aplicacion)">
      {{ aplicacion }}
    </v-btn>
  </v-col>
  </v-row>
</template>

<script>
// import calculadora from '../proyectos/aplicaciones/CalculadoraApp.vue'

export default {
  components: {
    // calculadora
  },
  data: () => ({
    currentApplication: false,
    option_01: false,
    aplicaciones: [
      "Calculadora",
      "Notas",
      "Notificaciones",
      "Calendario"
    ]
  }),
  methods: {
    setApplication() {
      // if(this.option_01){
      //   this.option_01 = false 
      // }
        // console.log('Dato Inicial: ',this.currentApplication);

        // if (this.currentApplication != aplicacion){
        //   this.option_01= true
        //   this.currentApplication = aplicacion
        //   console.log('Dato 02: ',this.currentApplication);
        // }
        // else if(this.currentApplication === aplicacion && this.option_01 === true){
        //   this.option_01= false

        // }
        // else if(this.currentApplication === aplicacion && this.option_01 === false){
        //   this.option_01= true

        // }
      
      
      // if (this.currentApplication === aplicacion){
      //   console.log(this.currentApplication);
      //   this.option_01 = false 
      // }
    }
  }
}
</script>


<style scoped>
.background-image {
  position: relative;
  background-image: url(../assets/images/photo-principal-gray.jpg);
  height: 700px;
  /* background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
}

.background-image::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20%; /* cubrir la mitad inferior de la imagen */
  background-image: linear-gradient(to top, #0d1017, #0d101700);
}
</style>
