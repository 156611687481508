import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router'
import './funciones-globales.js'

import '../public/css/styles.css'
import '../public/css/fonts.css'

import '../public/css/menuDerecho.css'
import menuDerecho from "./plugins/menuDerecho";

import '../public/css/styles-white.css'
import '../public/css/styles-dark.css'

loadFonts()

createApp(App).use(router)
  .use(vuetify, menuDerecho)
  .mount('#app')
