<template>
  <nav_bar/>
  <router-view/>
</template>

<script>
import nav_bar from './components/navegacion/NavBar'

export default{
  components:{
    nav_bar,
  }
}
</script>
